<template>
	<div>
		<progessBar/>
		<md-app-toolbar class="md-transparent" md-elevation="0">
			<span class="md-headline">LR Cloud</span>
			<md-tabs md-sync-route>
				<template slot="md-tab" slot-scope="{ tab }">
					<div>
						<md-badge md-content="!" md-dense v-if="tab.data.badge" style="right: 0px"/>
						{{tab.label}}
					</div>
				</template>
				<md-tab to="/home" md-label="Home"/>
				<md-tab to="/tools" md-label="Tools"/>
				<md-tab to="/settings" v-if="currentUser.roles.includes('admin')" :md-template-data="{ badge: newUser }" md-label="Settings"/>
			</md-tabs>
			<div style="text-align: right; padding-left: 20px">
				<md-menu md-direction="bottom-end" mdCloseOnSelect>
					<md-button class="md-icon-button md-dense md-raised md-primary" md-menu-trigger>
						<md-icon>more_horiz</md-icon>
						<md-tooltip md-direction="top">More</md-tooltip>
					</md-button>
					<md-menu-content>
						<md-menu-item @click="logout">Logout</md-menu-item>
					</md-menu-content>
				</md-menu>
			</div>
		</md-app-toolbar>
	</div>
</template>

<script>
import { UserRole } from '@/@types/interfaces'
import progessBar from '../ProgressBar.vue'
import Vue from 'vue'
export default {
	name: 'Toolbar',
	components: { progessBar },
	data() {
		return {}
	},
	computed: {
		newUser() {
			return this.$store.getters.user.find((u) => u.roles.length === 0)
		},
		currentUser() {
			return Vue.user()
		}
	},
	async created() {
		if (Vue.user().roles.includes(UserRole.ADMIN)) {
			await this.$store.dispatch('loadUser')
		}
	},
	methods: {
		async logout() {
			await this.$apiService.logout()
			window.location.href = `/login`
		}
	}
}
</script>