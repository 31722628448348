import {  IUser, IPaginationOptions, IOidcClient, ITool, IAccessToken, IToolCategory } from '@/@types/interfaces'
import Vue from 'vue'
import Vuex from 'vuex'
import ApiService from '../lib/ApiService'
import { snackbar } from './snackbar.module'
import { progressBar } from './progressBar.module'

Vue.use(Vuex)
const apiService: ApiService = new ApiService()

export default new Vuex.Store({
	modules: {
		snackbar,
		progressBar
	},
	state: {
		user: [] as IUser[],
		oidcClients: [] as IOidcClient[],
		accessToken: [] as IAccessToken[],
		tools: [] as ITool[],
		toolCategories: [] as IToolCategory[]
	},
	mutations: {
		async setUser(state, user) {
			state.user = user
		},
		async setOidcClients(state, oidcClients) {
			state.oidcClients = oidcClients
		},
		async setAccessToken(state, token) {
			state.accessToken = token
		},
		async setTools(state, tools) {
			state.tools = tools
		},
		async setToolCategories(state, toolCategories) {
			state.toolCategories = toolCategories
		}
	},
	getters: {
		user: state => state.user,
		oidcClients: state => state.oidcClients,
		accessToken: state => state.accessToken,
		tools: state => state.tools,
		toolCategories: state => state.toolCategories,
		toolsByCategory: (state) => (category: IToolCategory) => {
			return state.tools.filter((tool) => tool.toolCategory.id === category.id)
		}
	},
	actions: {
		async loadUser(state, queryOptions?: IPaginationOptions) {
			const user = await apiService.user.getAll(queryOptions)
			this.commit('setUser', user)
		},
		async loadOidcClients(state, queryOptions?: IPaginationOptions) {
			const oidcClients = await apiService.oidcClient.getAll(queryOptions)
			this.commit('setOidcClients', oidcClients)
		},
		async loadAccessToken(state, queryOptions?: IPaginationOptions) {
			const token = await apiService.accessToken.getAll(queryOptions)
			this.commit('setAccessToken', token)
		},
		async loadTools(state, queryOptions?: IPaginationOptions) {
			const token = await apiService.tool.getAll(queryOptions)
			this.commit('setTools', token)
		},
		async loadToolCategories(state, queryOptions?: IPaginationOptions) {
			const toolCategories = await apiService.toolCategory.getAll(queryOptions)
			this.commit('setToolCategories', toolCategories)
		}
	}
})