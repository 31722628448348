import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '../views/Login.vue'
import Forbidden from '../views/Forbidden.vue'
import NotFound from '../views/NotFound.vue'
import Home from '../views/Home.vue'
import Settings from '../views/Settings/Home.vue'
import Tools from '../views/Tools/Tools.vue'
import User from '../views/Settings/User.vue'
import OidcClient from '../views/Settings/OidcClient.vue'
import AccessToken from '../views/Settings/AccessToken.vue'
import { authGuard, onlyAdmin } from '@/lib/auth'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		redirect: 'home'
	},
	{
		path: '/home',
		name: 'Home',
		component: Home,
		beforeEnter: authGuard,
	},
	{
		path: '/tools',
		name: 'Tools',
		component: Tools,
		beforeEnter: authGuard,
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/forbidden',
		name: 'Forbidden',
		component: Forbidden
	},
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		beforeEnter: async (to: any, from: any, next: any) => {
			return authGuard(to, from, async () => {
				onlyAdmin(to, from, next)
			})
		},
		children: [
			{
				path: '',
				redirect: 'user'
			},
			{
				path: 'user',
				name: 'User',
				component: User
			},
			{
				path: 'oidcClient',
				name: 'OidcClient',
				component: OidcClient
			},
			{
				path: 'accessToken',
				name: 'AccessToken',
				component: AccessToken
			}
		]
	},
	{
		path: "*",
		component: NotFound
	}
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
