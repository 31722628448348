<template>
	<md-snackbar :md-active.sync="show">{{ message }}</md-snackbar>
</template>

<script>
export default {
	name: 'Snackbar',
	data() {
		return {
			show: false,
			message: ''
		}
	},
	created() {
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'snackbar/showMessage') {
				this.message = state.snackbar.content
				this.show = true
			}
		})
	}
}
</script>
