<template>
	<div id="app">
		<snackbar/>
		<div class="page-container">
			<router-view />
		</div>
	</div>
</template>

<script>
import snackbar from '@/components/Snackbar.vue'
export default {
	components: {
		snackbar
	},
	name: 'App',
	data() {
		return {}
	}
}
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100vh;
}
.md-app {
  height: 100%;
  min-height: 100vh;
}
.md-layout {
  width: 100%;
}
.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}
.link:hover {
  text-decoration: none;
}
.link {
  text-decoration: none;
  color: inherit;
}
.link:visited {
  text-decoration: none;
}
.md-dialog-container {
	width: 50%;
}
.filterInput {
	margin-right: 20px;
	width: 200px;
}
.md-progress-bar {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
}
.condition-editor textarea {
	border: none;
	overflow: auto;
	outline: none;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
}
.condition-editor{
	background: #2d2d2d;
	color: #ccc;

	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
	-moz-border-radius: 0.4em;
	border-radius: 0.4em;
}
.LRDtoken {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 400px;
	display: block;
}
a.md-headline:link {
	text-decoration: none;
	color: inherit;
}
a.md-headline:visited {
	text-decoration: none;
	color: inherit;
}
.package-dialog > .md-dialog-container {
	width: 70%;
}
.md-menu-content {
	z-index: 100!important;
}
.md-select + .md-clear {
	margin-right: 20px;
}
</style>
