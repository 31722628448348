import Vue from 'vue'
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
import App from './App.vue'
import router from './router'
import store from './store'
import snackbarPlugin from './plugins/snackbar'
import apiServicePlugin from './plugins/apiService'
import LibPlugin from './plugins/lib'

Vue.use(VueMaterial)

Vue.use(snackbarPlugin, { store })
Vue.use(apiServicePlugin)
Vue.use(LibPlugin)
Vue.config.productionTip = false

Vue.config.errorHandler = (err) => {
	if (process.env.NODE_ENV !== 'production') {
		// Vue JS error, refer to https://github.com/vuematerial/vue-material/issues/2285
		if (err.message !== "Cannot read property 'badInput' of undefined" && err.message !== "Cannot read properties of undefined (reading 'badInput')") {
			console.error(err);
		}
	}
}

new Vue({
	router,
	store,
	render: h => {
		return h(App)
	}
}).$mount('#app')
