<template>
	<div>
		<form novalidate class="md-layout">
			<md-dialog id="addDialog" :md-active.sync="showAddLink" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Add Link</md-dialog-title>
				<md-dialog-content>
					<md-field>
						<label for="link">Link</label>
						<md-input name="link" id="link" v-model="link"/>
					</md-field>
					<md-field>
						<label for="description">Description</label>
						<md-input name="description" id="description" v-model="description"/>
					</md-field>
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateLink()">Add</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'AddLink',
	mixins: [validationMixin],
	props: ['showAddLink'],
	data: () => ({
		link: null,
		description: null
	}),
	validations: {
		link: {
			required
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.link = null
			this.description = null
		},
		async validateLink() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				this.$emit('addLink', {link: this.link, description: this.description})
				this.close()
			}
		},
		close() {
			this.clearForm()
			this.$emit('close')
		}
	}
}
</script>

<style>
#addDialog .md-dialog-container {
	width: 20%!important;
}
</style>