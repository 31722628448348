import devConfig from './dev.env'
import prodConfig from './prod.env'
import stagingConfig from './staging.env'

const env = process.env.NODE_ENV ?? 'development'

let config = devConfig
if (env === 'production') {
	config = prodConfig
}

if (env === 'staging') {
	config = stagingConfig
}

export default config
