<template>
	<md-dialog :md-active.sync="showEdit" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="$emit('close')" @keydown.esc="$emit('close')">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout md-gutter">
				<div class="md-layout-item md-size-35">
					<md-field >
						<label for="category">Category</label>
						<md-select v-model="category" name="category" id="category" required @md-selected="update">
							<md-option v-for="category in $store.getters.toolCategories" :key="category.id" :value="category.id">{{category.name}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field :class="getValidationClass('name')">
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="tool.name" :disabled="sending" required />
						<span class="md-error" v-if="!$v.tool.name.required">Name is required</span>
					</md-field>
				</div>
				<div class="md-layout-item md-size-30">
					<md-field :class="getValidationClass('version')">
						<label for="secret">Version</label>
						<md-input name="version" id="version" v-model="tool.version" :disabled="sending" />
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label>Notes</label>
						<md-textarea v-model="tool.notes"></md-textarea>
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="$emit('close')" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'Edit',
	props: ['tool', 'showEdit'],
	data: () => ({
		sending: false,
		category: {}
	}),
	mixins: [validationMixin, debounce],
	watch: {
		tool: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	validations: {
		tool: {
			name: {
				required
			}
		}
	},
	created() {
		this.category = this.tool.toolCategory.id
		this.update = this.debounce(this.update)
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v.tool[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		async updateTool() {
			this.sending = true
			try {
				await this.$apiService.tool.update(this.tool.id, {
					toolCategoryId: this.category,
					name: this.tool.name,
					notes: this.tool.notes,
					version: this.tool.version
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.updateTool()
			}
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
