<template>
	<div>
		<form novalidate>
			<md-dialog :md-active.sync="showCreate" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field :class="getValidationClass('type')">
								<label for="type">Type</label>
								<md-select v-model="type" name="type" id="type">
									<md-option v-for="type in tokenTypes" :key="type.id" :value="type.val">{{type.text}}</md-option>
								</md-select>
								<span class="md-error" v-if="!$v.type.required">Type is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout">
						<div class="md-layout-item">
							<md-field>
								<label for="description">Description</label>
								<md-input name="description" id="description" v-model="description" :disabled="sending" />
							</md-field>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateAccessToken()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'Create',
	mixins: [validationMixin],
	props: ['showCreate'],
	data: () => ({
		name: null,
		description: null,
		type: null,
		sending: false,
		tokenTypes: [{
			id: 0,
			val: 'pipeline',
			text: 'Pipeline'
		}, {
			id: 1,
			val: 'service',
			text: 'Service'
		}]
	}),
	validations: {
		name: {
			required
		},
		type: {
			required
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.description = null
			this.type = null
			this.sending = false
		},
		async createAccessToken() {
			this.sending = true

			try {
				const token = await this.$apiService.accessToken.create({
					name: this.name,
					description: this.description,
					type: this.type
				})
				this.$snackbar.showMessage('Access token saved!')
				this.close(token)
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateAccessToken() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createAccessToken()
			}
		},
		close(token) {
			this.sending = false
			this.clearForm()
			this.$emit('close', token)
		}
	}
}
</script>
