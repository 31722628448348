export class Version {
	major: number = 0
	minor: number = 0
	patch: number = 0
	build: number = 0

	constructor(version?: string) {
		if (version) {
			this.split(version)
		}
	}

	private split(version: string): void {
		if (!Version.validString(version)) {
			throw new Error('Not a valid version string')
		}
		const regex = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:\+([0-9]+(?:\.[0-9-]+)*))?(?:\+[0-9]+)?$/
		const res = regex.exec(version)

		this.major = parseInt(res![1], 10)
		this.minor = parseInt(res![2], 10)
		this.patch = parseInt(res![3], 10)

		if (res![4] != null) {
			this.build = parseInt(res![4], 10)
		}
	}

	public toString(withoutBuild: boolean = false): string {
		if (withoutBuild) {
			return `${this.major}.${this.minor}.${this.patch}`
		}
		return `${this.major}.${this.minor}.${this.patch}${(this.build? `+${this.build}` : '')}`
	}

	public equals(value: string | Version): boolean {
		let o: Version
		if (value instanceof Version) {
			o = value
		} else {
			o = new Version(value)
		}

		if (o.build == null || this.build == null) {
			return o.toString(true) === this.toString(true)
		} else {
			return o.toString() === this.toString()
		}
	}

	public greater(value: string | Version): boolean {
		let o: Version
		if (value instanceof Version) {
			o = value
		} else {
			o = new Version(value)
		}

		if (o.toString() === '' || this.toString() === '') {
			return true
		}

		if (this.major > o.major) {
			return true
		} else if (this.major < o.major) {
			return false
		}

		if (this.minor > o.minor) {
			return true
		} else if (this.minor < o.minor) {
			return false
		}

		if (this.patch > o.patch) {
			return true
		} else if (this.patch < o.patch) {
			return false
		}

		if (this.build != null && o.build != null) {
			if (this.build > o.build) {
				return true
			} else if (this.build < o.build) {
				return false
			}
		}

		return false
	}

	public less(value: string | Version): boolean {
		let o: Version
		if (value instanceof Version) {
			o = value
		} else {
			o = new Version(value)
		}

		if (o.toString() === '' || this.toString() === '') {
			return true
		}

		if (this.major < o.major) {
			return true
		} else if (this.major < o.major) {
			return false
		}

		if (this.minor < o.minor) {
			return true
		} else if (this.minor < o.minor) {
			return false
		}

		if (this.patch < o.patch) {
			return true
		} else if (this.patch < o.patch) {
			return false
		}

		if (this.build != null && o.build != null) {
			if (this.build < o.build) {
				return true
			} else if (this.build < o.build) {
				return false
			}
		}

		return false
	}

	static validString(version: string): boolean {
		const regex = /^([0-9]+)\.([0-9]+)\.([0-9]+)(?:\+([0-9]+(?:\.[0-9-]+)*))?(?:\+[0-9]+)?$/
		const res = regex.exec(version)

		if (!res) {
			return false
		}

		if (res[1] == null || res[2] == null || res[3] == null) {
			return false
		}

		return true
	}
}