export default {
	methods: {
		debounce(func) {
			let debounceTimer
			return function() {
				const context = this
				const args = arguments
				clearTimeout(debounceTimer)
				debounceTimer = setTimeout(async () => await func.apply(context, args), 500)
			}
		}
	}
}