<template>
	<div>
		<edit v-if="showEdit" :showEdit="showEdit" :oidcClient="selectedOidcClient" @close="closeEdit"/>
		<create v-if="showCreate" :showCreate="showCreate" @close="closeCreate"/>
		<e-table title="Oidc Clients" singularTitle="Oidc Client" :data="$store.getters.oidcClients" :filter="filter" :columns="columns"
		@delete="deleteOidcClient" @showEditDialog="showOidcClient" @showCreateDialog="showCreate = true" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import Create from '@/components/OidcClient/Create.vue'
import Edit from '@/components/OidcClient/Edit.vue'

export default {
	name: 'OidcClient',
	components: {
		'e-table': Table,
		'edit': Edit,
		'create': Create,
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			showEdit: false,
			showCreate: false,
			selectedOidcClient: null,
			filter: {
				name: {
					value: null,
					name: 'Name',
					property: 'name'
				},
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'oidcId',
					label: 'Oidc Id'
				}, {
					name: 'roles',
					label: 'Roles',
					render: (roles) => roles.join(', ')
				}, {
					name: 'redirectUris',
					label: 'Redirect URIs',
					render: (uris) => uris.join(', ')
				}
			]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadOidcClients', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async showOidcClient(oidcClient) {
			this.selectedOidcClient = Object.assign({}, oidcClient)
			this.showEdit = true
		},
		async closeEdit() {
			try {
				await this.$store.dispatch('loadOidcClients', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.showEdit = false
		},
		async deleteOidcClient(id) {
			try {
				await this.$apiService.oidcClient.delete(id)
				await this.$store.dispatch('loadOidcClients', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async closeCreate() {
			this.showCreate = false
			try {
				await this.$store.dispatch('loadOidcClients', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		}
	}
}
</script>