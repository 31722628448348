/* eslint-disable */
export enum UserRole {
	NO_PERMISSION = 'noPermission',
	ADMIN = 'admin',
	VIEWER = 'viewer'
}

export enum AccessTokenType {
	PIPELINE = 'pipeline',
	SERVICE = 'service'
}
/* eslint-enable */

export interface IPaginationOptions {
	sortBy: string
	sortOrder: 'ASC' | 'DESC'
	offset?: number
	limit?: number
	all?: boolean
	filter: {
		key: string,
		value: string
	}[]
}

export interface IUser {
	email: string
	name: string
	role: UserRole
}

export interface IOidcClient {
	oidcId: string
	name: string
	roles: string[]
	redirectUris: string[]
}

export interface ITool {
	id: number
	name: string
	file: string
	uploaded: Date
	toolCategory: IToolCategory
}

export interface IToolCategory {
	id: number
	name: string
}

export interface IAccessToken {
	id: number
	name: string
	description: string
	token?: string
	type: AccessTokenType
}


export type Entities = IUser | IOidcClient | ITool | IAccessToken | IToolCategory
