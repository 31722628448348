<template>
	<div>
		<form novalidate>
			<md-dialog :md-active.sync="showCreate" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required />
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
							</md-field>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateCategory()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'Create',
	mixins: [validationMixin],
	props: ['showCreate'],
	data: () => ({
		name: null,
		sending: false,
	}),
	validations: {
		name: {
			required
		}
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.sending = false
		},
		async createCategory() {
			this.sending = true

			try {
				const data = { name: this.name }
				await this.$apiService.toolCategory.create(data)
				this.$snackbar.showMessage('Category saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateCategory() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createCategory()
			}
		},
		close() {
			this.sending = false
			this.clearForm()
			this.$emit('close')
		}
	}
}
</script>
