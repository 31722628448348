<template>
	<div class="md-toolbar-row">
		<span style="font-size: 17px; padding-top: 5px; margin-right: 20px;">Filter:</span>
		<div v-for="f of filterFields" :key="f.name">
			<md-field md-clearable class="filterInput" v-if="!f.type || (f.type !== 'date' && f.type !== 'boolean')" autocomplete="off">
				<md-input :placeholder="`${f.name}...`" v-model="f.value" />
			</md-field>
			<md-datepicker md-clearable class="filterInput" :md-model-type="String" v-model="f.value" v-if="f.type && f.type === 'date'">
				<label>{{f.name}}</label>
			</md-datepicker>
			<md-checkbox v-if="f.type && f.type === 'boolean'" v-model="f.value">{{f.name}}</md-checkbox>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
export default {
	name: 'TableFilter',
	props: ['filter', 'data'],
	computed: {
		filterFields() {
			const tFiler = {...this.filter}
			delete tFiler.tag
			return tFiler
		}
	},
	watch: {
		filter: {
			deep: true,
			handler() {
				this.applyFilter()
			}
		},
		data: {
			deep: true,
			handler() {
				this.applyFilter()
			}
		}
	},
	methods: {
		applyFilter() {
			let copy = [...this.data]
			for (let f of Object.keys(this.filter)) {
				if (this.filter[f].type && this.filter[f].type === 'date') {
					copy = Vue.searchByDate(this.filter[f].property, this.filter[f].value, copy)
					continue
				}
				if (this.filter[f].type && this.filter[f].type === 'boolean') {
					if (this.filter[f].fn) {
						copy = this.filter[f].fn(copy, this.filter[f].value)
					}
					continue
				}
				if (this.filter[f].fn) {
					copy = this.filter[f].fn(copy, this.filter[f].value)
					continue
				}
				if (f === 'tag') {
					copy = Vue.searchByTag(this.filter[f].value, copy)
					continue
				}
				copy = Vue.searchByField(this.filter[f].property, this.filter[f].value, copy)
			}

			this.$emit('applyFilter', copy)
		}
	}
}
</script>