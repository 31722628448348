<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1">
			<toolbar />
		</md-app-toolbar>
		<md-app-drawer md-permanent="clipped">
			<md-list>
				<md-list-item to="user">
					<span class="md-list-item-text">User</span>
					<md-badge md-content="!" md-dense :class="{'hidden': !newUser}"/>
				</md-list-item>
				<md-list-item to="oidcClient">
					<span class="md-list-item-text">Oidc Clients</span>
				</md-list-item>
				<md-list-item to="accessToken">
					<span class="md-list-item-text">Access token</span>
				</md-list-item>
			</md-list>
		</md-app-drawer>
		<md-app-content>
			<router-view />
		</md-app-content>
	</md-app>
</template>

<script>
import Toolbar from '@/components/Common/Toolbar.vue'
export default {
	name: 'SettingsHome',
	components: { toolbar: Toolbar },
	computed: {
		newUser() {
			return this.$store.getters.user.find((u) => u.roles.length === 0)
		}
	}
}
</script>

<style scoped>
.hidden {
	visibility: hidden;
}
</style>