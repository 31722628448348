<template>
	<div>
		<form novalidate>
			<md-dialog :md-active.sync="showCreate" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item md-size-35">
							<md-field :class="getValidationClass('category')">
								<label for="category">Category</label>
								<md-select v-model="category" name="category" id="category" required>
									<md-option v-for="category in $store.getters.toolCategories" :key="category.id" :value="category.id">{{category.name}}</md-option>
								</md-select>
								<span class="md-error" v-if="!$v.category.required">Category is required</span>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field>
								<label for="file">File</label>
								<md-file id="fileInput" @md-change="onFileSelect"/>
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required />
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
							</md-field>
						</div>
						<div class="md-layout-item md-size-30">
							<md-field :class="getValidationClass('version')">
								<label for="secret">Version</label>
								<md-input name="version" id="version" v-model="version" :disabled="sending" />
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label>Notes</label>
								<md-textarea v-model="notes"></md-textarea>
							</md-field>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateTool()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'

export default {
	name: 'Create',
	mixins: [validationMixin],
	props: ['showCreate'],
	data: () => ({
		name: null,
		category: null,
		notes: null,
		version: null,
		sending: false,
	}),
	validations: {
		name: {
			required
		},
		category: {
			required
		}
	},
	created() {
		this.$store.dispatch('loadTools')
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.category = null
			this.notes = null
			this.version = null
			this.sending = false
		},
		async createTool() {
			this.sending = true

			try {
				const data = {
					name: this.name,
					toolCategoryId: this.category,
					notes: this.notes ?? '',
					version: this.version ?? '',
					file: document.querySelector('#fileInput').files[0]
				}
				await this.$apiService.tool.create(data)
				this.$snackbar.showMessage('Tool saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		onFileSelect() {
			if (document.querySelector('#fileInput').files.length === 1) {
				this.name = document.querySelector('#fileInput').files[0].name
			}
		},
		async validateTool() {
			this.$v.$touch()

			if (!this.$v.$invalid && document.querySelector('#fileInput').files.length === 1) {
				await this.createTool()
			}
		},
		close() {
			this.sending = false
			this.clearForm()
			this.$emit('close')
		}
	}
}
</script>
