<template>
	<div>
		<md-dialog-alert
		:md-active.sync="showAccessTokenToken"
		md-title="Access Token"
		:md-content="token">
		</md-dialog-alert>
		<create-access-token v-if="showCreateAccessToken" :showCreate="showCreateAccessToken" @close="closeCreate"/>
		<e-table title="Access Token" singularTitle="Access Token" :data="$store.getters.accessToken" :columns="columns" @showCreateDialog="showCreateAccessToken = true"
		@delete="deleteAccessToken" :readonly="false" :editable="false" :deleteable="true" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}" :customActions="customActions"/>
	</div>
</template>

<script>
import Table from '@/components/Common/Table'
import CreateAccessToken from '@/components/AccessToken/Create.vue'
import { AccessTokenType } from '@/@types/interfaces'

export default {
	name: 'AccessToken',
	components: {
		'e-table': Table,
		'create-access-token': CreateAccessToken,
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			showCreateAccessToken: false,
			showAccessTokenToken: false,
			token: '',
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'description',
					label: 'Description'
				}, {
					name: 'type',
					label: 'Type',
					render: (type) => {
						switch (type) {
							case AccessTokenType.SERVICE: return 'Service'
							case AccessTokenType.PIPELINE: return 'Pipeline'
						}
					}
				}
			],
			customActions: [{
				icon: 'visibility',
				tooltip: 'Show token',
				handler: (token) => {
					this.token = token.token
					this.showAccessTokenToken = true
				}
			}]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadAccessToken', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async deleteAccessToken(id) {
			try {
				await this.$apiService.accessToken.delete(id)
				await this.$store.dispatch('loadAccessToken', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async closeCreate(token) {
			this.showCreateAccessToken = false
			if (token) {
				this.token = token.token
				this.showAccessTokenToken = true
			}
			try {
				await this.$store.dispatch('loadAccessToken', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
	}
}
</script>