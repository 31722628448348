const initialState = {
	content: ''
}

export const snackbar = {
	namespaced: true,
	state: initialState,
	mutations: {
		showMessage(state: any, message: any): any {
			state.content = message
		}
	}
}
