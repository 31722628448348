<template>
	<md-progress-bar md-mode="query" v-if="loading"></md-progress-bar>
</template>

<script>
export default {
	name: 'ProgressBar',
	data() {
		return {
			loading: false
		}
	},
	created() {
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === 'progressBar/show') {
				this.loading = state.progressBar.loading
			}
		})
	}
}
</script>
