<template>
	<md-app>
		<md-app-toolbar class="md-transparent" md-elevation="1">
			<toolbar />
		</md-app-toolbar>
		<md-app-drawer md-permanent="clipped">
			<md-list>
				<md-list-item v-for="category in $store.getters.toolCategories" :class="{'router-link-active': selectedCategory.id == category.id}" :key="category.id" @click="selectedCategory = category">
					<span class="md-list-item-text ">{{category.name}}</span>
					<md-button v-if="currentUser.roles.includes('admin')" class="md-icon-button md-accent" :disabled="$store.getters.toolsByCategory(category).length != 0" @click="deleteCategory(category)">
						<md-icon>delete</md-icon>
					</md-button>
				</md-list-item>
				<md-list-item v-if="currentUser.roles.includes('admin')">
					<span class="md-list-item-text"><md-button @click="createCategory = true"><md-icon>add</md-icon></md-button></span>
				</md-list-item>
			</md-list>
		</md-app-drawer>
		<md-app-content>
			<div>
				<create-category :showCreate="createCategory" @close="closeCreateCategory"/>
				<create v-if="showCreate" :showCreate="showCreate" @close="closeCreate"/>
				<edit v-if="showEdit" :showEdit="showEdit" @close="closeEdit" :tool="selectedTool"/>
				<e-table title="Tools" singularTitle="Tool" :data="tools" :filter="filter" :columns="columns"
				@delete="deleteTool" @showEditDialog="showTool" @showCreateDialog="showCreate = true" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}" :customActions="customActions"/>
			</div>
		</md-app-content>
	</md-app>
</template>

<script>
import Vue from 'vue'
import Toolbar from '@/components/Common/Toolbar.vue'
import Table from '@/components/Common/Table'
import CreateCategory from '@/components/ToolCategory/Create.vue'
import Create from '@/components/Tool/Create.vue'
import Edit from '@/components/Tool/Edit.vue'
import config from '@/../config'

export default {
	name: 'Tools',
	components: {
		'e-table': Table,
		'create': Create,
		'edit': Edit,
		toolbar: Toolbar,
		'create-category': CreateCategory
	},
	data: function() {
		return {
			createCategory: false,
			selectedCategory: {},
			sortBy: 'id',
			sortOrder: 'DESC',
			showEdit: false,
			showCreate: false,
			apiUrl: config.API_URL,
			selectedTool: null,
			filter: {
				name: {
					value: null,
					name: 'Name',
					property: 'name'
				},
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'version',
					label: 'Version'
				}, {
					name: 'notes',
					label: 'Notes',
					render: (val) => {
						if (val) {
							const max = 27
							let ret = val.substring(0, max)
							if (val.length > max) {
								ret = ret.padEnd(30, '...')
							}
							return ret
						}
						return ''
					}
				}, {
					name: 'uploaded',
					label: 'Uploaded',
					render: (val) => Vue.formatDate(val)

				},
			],
			customActions: [{
				icon: 'share',
				tooltip: 'Share',
				handler: async (tool) => this.copyToClipboard(tool.file)
			}, {
				icon: 'download',
				tooltip: 'Download',
				handler: async (tool) => window.location.href = `${this.apiUrl}/tool/${tool.file}`
			}]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadToolCategories')
			await this.$store.dispatch('loadTools', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			this.selectedCategory = this.$store.getters.toolCategories[0]
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	computed: {
		tools() {
			if (this.selectedCategory) {
				return this.$store.getters.tools.filter((tool) => tool.toolCategory.id === this.selectedCategory.id)
			}
			return []
		},
		currentUser() {
			return Vue.user()
		}
	},
	methods: {
		closeCreateCategory() {
			this.createCategory = false
			this.$store.dispatch('loadToolCategories')
		},
		async deleteCategory(category) {
			try {
				await this.$apiService.toolCategory.delete(category.id)
				await this.$store.dispatch('loadToolCategories')
				if (this.selectedCategory.id === category.id) {
					this.selectedCategory = this.$store.getters.toolCategories[0]
				}
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async showTool(tool) {
			this.selectedTool = Object.assign({}, tool)
			this.showEdit = true
		},
		async closeEdit() {
			try {
				await this.$store.dispatch('loadTools', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.showEdit = false
		},
		async deleteTool(id) {
			try {
				await this.$apiService.tool.delete(id)
				await this.$store.dispatch('loadTools', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async closeCreate() {
			this.showCreate = false
			try {
				await this.$store.dispatch('loadTools', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
		},
		async copyToClipboard(file) {
			await navigator.clipboard.writeText(`${this.apiUrl}/tool/${file}`)
			this.$snackbar.showMessage('Link copied to clipboard!')
		}
	}
}
</script>