<template>
	<div>
		<form novalidate>
			<md-dialog :md-active.sync="showCreate" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="close" @keydown.esc="close">
				<md-dialog-title>Create</md-dialog-title>
				<md-dialog-content>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('name')">
								<label for="name">Name</label>
								<md-input name="name" id="name" v-model="name" :disabled="sending" required/>
								<span class="md-error" v-if="!$v.name.required">Name is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field :class="getValidationClass('secret')">
								<label for="secret">Secret</label>
								<md-input name="secret" id="secret" v-model="secret" :disabled="sending" required />
								<span class="md-error" v-if="!$v.secret.required">Secret is required</span>
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field :class="getValidationClass('prefix')">
								<label for="secret">Prefix</label>
								<md-input name="prefix" id="prefix" v-model="prefix" :disabled="sending" required />
								<span class="md-error" v-if="!$v.prefix.required">Prefix is required</span>
							</md-field>
						</div>
					</div>
					<div class="md-layout md-gutter">
						<div class="md-layout-item">
							<md-field>
								<label for="redirectUris">Redirect Uris (comma seperated)</label>
								<md-input name="redirectUris" id="redirectUris" v-model="redirectUris" :disabled="sending" />
							</md-field>
						</div>
						<div class="md-layout-item">
							<md-field>
								<label for="roles">Roles (comma seperated)</label>
								<md-input name="roles" id="roles" v-model="roles" :disabled="sending" />
							</md-field>
						</div>
					</div>

					<md-progress-bar md-mode="indeterminate" v-if="sending" />
					<md-dialog-actions>
						<md-button class="md-primary" @click="close()">Cancel</md-button>
						<md-button type="submit" class="md-primary" @click="validateOidcClient()" :disabled="sending">Create</md-button>
					</md-dialog-actions>
				</md-dialog-content>
			</md-dialog>
		</form>
	</div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import {
	required
} from 'vuelidate/lib/validators'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Create',
	mixins: [validationMixin],
	components: { 'dialog-link-table': DialogLinkTable },
	props: ['showCreate'],
	data: () => ({
		name: null,
		secret: null,
		prefix: null,
		roles: null,
		redirectUris: null,
		sending: false,
	}),
	validations: {
		name: {
			required
		},
		secret: {
			required
		},
		prefix: {
			required
		}
	},
	created() {
		this.$store.dispatch('loadOidcClients')
	},
	methods: {
		getValidationClass(fieldName) {
			const field = this.$v[fieldName]

			if (field) {
				return {
					'md-invalid': field.$invalid && field.$dirty
				}
			}
		},
		clearForm() {
			this.$v.$reset()
			this.name = null
			this.secret = null
			this.prefi = null
			this.roles = null
			this.redirectUris = null
			this.sending = false
		},
		async createOidcClient() {
			this.sending = true

			try {
				await this.$apiService.oidcClient.create({
					name: this.name,
					secret: this.secret,
					prefix: this.prefix,
					roles: this.roles.split(','),
					redirectUris: this.redirectUris.split(',')
				})
				this.$snackbar.showMessage('OidcClient saved!')
				this.close()
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async validateOidcClient() {
			this.$v.$touch()

			if (!this.$v.$invalid) {
				await this.createOidcClient()
			}
		},
		close() {
			this.sending = false
			this.clearForm()
			this.$emit('close')
		}
	}
}
</script>
