<template>
	<md-dialog :md-active.sync="showEdit" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="$emit('close')" @keydown.esc="$emit('close')">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="oidcClient.name" readonly required/>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="prefix">Prefix</label>
						<md-input name="prefix" id="prefix" v-model="oidcClient.prefix" readonly required/>
					</md-field>
				</div>
			</div>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label for="redirectUris">Redirect Uris (comma seperated)</label>
						<md-input name="redirectUris" id="redirectUris" v-model="oidcClient.redirectUris" />
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="roles">Roles (comma seperated)</label>
						<md-input name="roles" id="roles" v-model="oidcClient.roles" />
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="$emit('close')" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Edit',
	props: ['oidcClient', 'showEdit'],
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		sending: false
	}),
	mixins: [debounce],
	watch: {
		oidcClient: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	created() {
		this.update = this.debounce(this.update)
		this.oidcClient.roles = this.oidcClient.roles.join(', ')
		this.oidcClient.redirectUris = this.oidcClient.redirectUris.join(', ')
	},
	methods: {
		async updateOidcClient() {
			this.sending = true
			try {
				await this.$apiService.oidcClient.update(this.oidcClient.id, {
					roles: this.oidcClient.roles.split(','),
					redirectUris: this.oidcClient.redirectUris.split(',')
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			await this.updateOidcClient()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
