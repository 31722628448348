const initialState = {
	loading: false
}

let count = 0

export const progressBar = {
	namespaced: true,
	state: initialState,
	mutations: {
		show(state: any, loading: boolean): any {
			if (loading) {
				count++
			} else {
				count--
			}
			state.loading = count > 0
		}
	}
}
