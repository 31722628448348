<template>
	<md-dialog :md-active.sync="showEdit" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="$emit('close')" @keydown.esc="$emit('close')">
		<md-dialog-title>Edit</md-dialog-title>
		<md-dialog-content>
			<div class="md-layout md-gutter">
				<div class="md-layout-item">
					<md-field>
						<label for="name">Name</label>
						<md-input name="name" id="name" v-model="user.name" readonly required/>
					</md-field>
				</div>
				<div class="md-layout-item">
					<md-field>
						<label for="email">email</label>
						<md-input name="email" id="email" v-model="user.email" readonly required />
					</md-field>
				</div>
			</div>
			<div class="md-layout">
				<div class="md-layout-item">
					<md-field>
						<label>Roles</label>
						<md-select v-model="user.roles" name="role" id="role" multiple>
							<md-option v-for="(role, i) in roles" :key="i" :value="role">{{role}}</md-option>
						</md-select>
					</md-field>
				</div>
			</div>

			<md-progress-bar md-mode="indeterminate" v-if="sending" />
			<md-dialog-actions>
				<md-button class="md-primary" @click="$emit('close')" :disabled="sending">Close</md-button>
			</md-dialog-actions>
		</md-dialog-content>
	</md-dialog>
</template>

<script>
import debounce from '../../mixins/debounce'
import DialogLinkTable from '../Common/DialogLinkTable.vue'

export default {
	name: 'Edit',
	props: ['user', 'showEdit'],
	components: { 'dialog-link-table': DialogLinkTable },
	data: () => ({
		sending: false,
		roles: []
	}),
	mixins: [debounce],
	watch: {
		user: {
			deep: true,
			async handler() {
				await this.update()
			}
		}
	},
	async created() {
		this.update = this.debounce(this.update)
		this.roles = await this.$apiService.user.getRoles()
	},
	methods: {
		async updateUser() {
			this.sending = true
			try {
				await this.$apiService.user.update(this.user.id, {
					roles: this.user.roles
				})
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.sending = false
		},
		async update() {
			await this.updateUser()
		}
	}
}
</script>

<style scoped>
.info {
	margin-left: 20px
}
</style>
