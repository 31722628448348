import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import config from '../../config'
import router from '../router'
import { Entities, IUser, IOidcClient, IPaginationOptions, ITool, IAccessToken, IToolCategory } from '@/@types/interfaces'
import store from '@/store'

axios.defaults.withCredentials = true
axios.defaults.baseURL = config.API_URL

axios.interceptors.response.use((response: any) => {
	store.commit('progressBar/show', false, { root: true })
	return response
}, (error: any) => {
	store.commit('progressBar/show', false, { root: true })
	if (error.response.status === 401) {
		router.push('/login')
		throw new Error('Session expired. Please login!')
	}
	throw new Error(error.response.data.message)
})

axios.interceptors.request.use((val: AxiosRequestConfig) => {
	store.commit('progressBar/show', true, { root: true })
	return val
})

export default class ApiService {
	user: UserApi = new UserApi(axios)
	oidcClient: OidcClientApi = new OidcClientApi(axios)
	tool: ToolApi = new ToolApi(axios)
	toolCategory: ToolCategoryApi = new ToolCategoryApi(axios)
	accessToken: AccessTokenApi = new AccessTokenApi(axios)

	public async logout(): Promise<void> {
		await axios.get('/auth/logout')
	}
}

class EntityApi<T extends Entities> {
	name: string
	url: string
	ax: typeof axios

	constructor(name: string, ax: typeof axios) {
		this.name = name
		this.url = `/${this.name}`
		this.ax = ax
	}

	public async getAll(queryOptions?: IPaginationOptions): Promise<T[]> {
		const res: AxiosResponse = await this.ax.get(this.url, { params: queryOptions })
		const entities: T[] = res.data
		return entities
	}

	public async getOne(id: number): Promise<T> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/${id}`)
		const entity: T = res.data as T
		return entity
	}

	public async create(data: any): Promise<T> {
		Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		return (await this.ax.post(this.url, {...data})).data
	}

	public async delete(id: number): Promise<void> {
		await this.ax.delete(`${this.url}/${id}`)
	}

	public async update(id: number, data: any, allowNull: boolean = false): Promise<T> {
		if (!allowNull) {
			Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		}
		return (await this.ax.put(`${this.url}/${id}`, {...data})).data
	}
}

export class UserApi extends EntityApi<IUser> {
	constructor(ax: typeof axios) {
		super('user', ax)
	}

	public async getRoles(): Promise<string[]> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/roles`)
		return res.data
	}
}

export class OidcClientApi extends EntityApi<IOidcClient> {
	constructor(ax: typeof axios) {
		super('oidcClient', ax)
	}
}

export class ToolCategoryApi extends EntityApi<IToolCategory> {
	constructor(ax: typeof axios) {
		super('toolCategory', ax)
	}
}

export class ToolApi extends EntityApi<ITool> {
	constructor(ax: typeof axios) {
		super('tool', ax)
	}

	public async getUploadUrl(): Promise<string> {
		const res: AxiosResponse = await this.ax.get(`${this.url}/upload-url/by-user`)
		return res.data
	}

	public async create(data: any): Promise<any> {
		Object.keys(data).forEach((k) => data[k] == null && delete data[k])
		const uploadUrl = await this.getUploadUrl()
		await fetch(uploadUrl, {
			method: 'PUT',
			body: data.file,
			headers: {
				'Content-Type': 'application/octet-stream' // eslint-disable-line
			}
		})
		delete data.file
		data.uploadUrl = uploadUrl
		return (await this.ax.post(`${this.url}/by-user`, data)).data
	}
}

export class AccessTokenApi extends EntityApi<IAccessToken> {
	constructor(ax: typeof axios) {
		super('accessToken', ax)
	}
}