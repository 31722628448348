<template>
	<div>
		<md-dialog :md-active.sync="showConfirm" :md-click-outside-to-close=false :md-close-on-esc=false @md-clicked-outside="$emit('onCancel')" @keydown.esc="$emit('onCancel')">
			<md-dialog-title>Confirm</md-dialog-title>
			<md-dialog-content>
				Delete this item?
				<md-dialog-actions>
					<md-button class="md-primary" @click="$emit('onCancel')">Cancel</md-button>
					<md-button class="md-primary md-accent" @click="$emit('onConfirm')" >Confirm</md-button>
				</md-dialog-actions>
			</md-dialog-content>
		</md-dialog>
	</div>
</template>

<script>
export default {
	name: 'ConfirmDelete',
	props: ['showConfirm']
}
</script>