import config from '@/../config'
import router from '../router'

function getCookie(cname: string) {
	const name = cname + "="
	const decodedCookie = decodeURIComponent(document.cookie)
	const ca = decodedCookie.split(';')
	for(let i = 0; i < ca.length; i++) {
		let c = ca[i]
		while (c.charAt(0) === ' ') {
			c = c.substring(1)
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length)
		}
	}
	return ''
}

function isAuthenticated(): boolean {
	const cookieUser = getUserCookie()
	if (cookieUser === '') {
		return false
	}
	return true
}

function authGuard(to: any, from: any, next: any) {
	const cookieUser = getUserCookie()
	if (cookieUser === '') {
		router.push('/login')
		return
	}
	const user = JSON.parse(atob(cookieUser))
	if (user.roles.length === 0) {
		router.push('/forbidden')
		return
	}

	if (isAuthenticated()) {
		return next()
	}

	router.push('/login')
	return
}

function onlyAdmin(to: any, from: any, next: any) {
	const cookieUser = getUserCookie()
	const user = JSON.parse(atob(cookieUser))
	if (user.roles.includes('admin')) {
		next()
		return
	}
	router.push('/forbidden')
	return
}

function getUserCookie() {
	return getCookie(`${config.COOKIE_PREFIX}_user`)
}

export { authGuard, onlyAdmin, getUserCookie }