<template>
	<div>
		<edit v-if="showEdit" :showEdit="showEdit" :user="selectedUser" @close="closeEdit"/>
		<e-table title="User" singularTitle="User" :data="$store.getters.user" :filter="filter" :columns="columns"
		@showEditDialog="showUser" @delete="deleteUser" @setSortBy="val => {sortBy = val}" @setSortOrder="val => {sortOrder = val}"/>
	</div>
</template>

<script>
import Vue from 'vue'
import Table from '@/components/Common/Table'
import Edit from '@/components/User/Edit.vue'
import ConfirmDelete from '@/components/Common/ConfirmDelete.vue'

export default {
	name: 'User',
	components: {
		'e-table': Table,
		'edit': Edit,
		'confirm-delete': ConfirmDelete,
	},
	data: function() {
		return {
			sortBy: 'id',
			sortOrder: 'DESC',
			showEdit: false,
			selectedUser: null,
			filter: {
				name: {
					value: null,
					name: 'Name',
					property: 'name'
				},
				roles: {
					value: null,
					name: 'Role',
					property: 'roles',
					fn: (data, val) => {
						if (!val) {
							return data
						}
						return data.filter((item) => {
							return item.roles.some((role) => role.toLowerCase().indexOf(val.toLowerCase()) > -1)
						})
					}
				}
			},
			columns: [
				{
					name: 'id',
					label: 'ID'
				}, {
					name: 'name',
					label: 'Name'
				}, {
					name: 'email',
					label: 'Email'
				}, {
					name: 'roles',
					label: 'Roles',
					render: (val) => val.join(', ')
				}
			]
		}
	},
	async created() {
		try {
			await this.$store.dispatch('loadUser', { sortBy: this.sortBy, sortOrder: this.sortOrder })
		} catch (e) {
			this.$snackbar.showMessage(e)
		}
	},
	methods: {
		async showUser(user) {
			this.selectedUser = Object.assign({}, user)
			this.showEdit = true
		},
		async closeEdit() {
			try {
				await this.$store.dispatch('loadUser', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.showEdit = false
		},
		async deleteUser(id) {
			try {
				if (Vue.user().id === id) {
					const err = new Error('Cannot delete yourself')
					this.$snackbar.showMessage(err)
					return
				}
				await this.$apiService.user.delete(id)
				await this.$store.dispatch('loadUser', { sortBy: this.sortBy, sortOrder: this.sortOrder })
			} catch (e) {
				this.$snackbar.showMessage(e)
			}
			this.showDelete = false
		}
	}
}
</script>