import Fuse from 'fuse.js'
import { getUserCookie } from '@/lib/auth'

function getNestedField(field: string, object: any): any {
	return field.split('.').reduce((prev, curr) => prev[curr], object)
}

export default class {
	static install(Vue: any, options?: any[]): void { //eslint-disable-line
		Vue.subStringSearch = function(field: string, pattern: string, data: any[]) {
			if (!pattern) {
				return data
			}

			return data.filter((s) => getNestedField(field, s).toLowerCase().includes(pattern.toLowerCase()))
		}

		Vue.searchByField = function(field: string, pattern: string, data: any[]) {
			if (!pattern) {
				return data
			}
			const options = {
				keys: [field]
			}
			const fues = new Fuse(data, options)
			data = fues.search(pattern).map(i => i.item)
			return data
		}

		Vue.searchByDate = function(field: string, date: Date, data: any[]) {
			if (!date) {
				return data
			}
			return data.filter((s) => getNestedField(field, s).split('T')[0] === date)
		}

		Vue.formatDate = function (input: string) {
			const date = new Date(input)
			return date.toLocaleDateString()
		}

		Vue.user = function() {
			const cookieUser = getUserCookie()
			const user = JSON.parse(atob(cookieUser))
			return user
		}
	}
}