/* eslint-disable */
export default {
	NODE_ENV: 'production',
	API_URL: 'https://cloud.lr-lab.com/api',
	PROTOCOL_DOCU_URL: 'https://cloud.lr-lab.com/api/protocol-documentation',
	GOOGLE_AUTH_URL: 'https://cloud.lr-lab.com/api/auth/google',
	MICROSOFT_AUTH_URL: 'https://cloud.lr-lab.com/api/auth/microsoft',
	UUID_URL: 'https://occhio-controls.lr-lab.com',
	LR_DISTRIBUTION_URL: 'https://distribution.lr-lab.com',
	LR_PRODUCTION_URL: 'https://production.lr-lab.com',
	NXT_SERVICE_CLOUD_URL: 'https://nxt-service-cloud.lr-lab.com',
	OCCHIO_ELECTRONICS_CLOUD_URL: 'https://occhio-electronics.lr-lab.com',
	COOKIE_PREFIX: 'lr_cloud'
}
/* eslint-enable */
