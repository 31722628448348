<template>
	<div style="text-align: center">
		<md-card class="md-elevation-1">
			<md-card-header>
				<div class="title">
					<p>404</p>
					<p>Not Found!</p>
				</div>
			</md-card-header>
			<md-card-content>
			</md-card-content>
		</md-card>
	</div>
</template>

<script>

export default {
	name: 'NotFound'
}
</script>

<style scoped>
.md-card {
	width: 400px;
	height: 400px;
	margin-top: 100px;
	display: inline-block;
	vertical-align: top;
}
.title {
	margin-top: 100px;
	font-size: 50px;
}
</style>