<template>
	<div>
		<add-link v-if="showAddLink" :showAddLink="showAddLink" @addLink="addLink" @close="showAddLink = false"/>
		<md-subheader>
			Links:
			<md-button @click="showAddLink = true" class="md-icon-button">
				<md-icon>add</md-icon>
			</md-button>
		</md-subheader>
		<ul style="list-style-type:none; padding: 0px; margin: 0px;">
			<li v-for="(link, id) in links" :key="id" class="link">
				<table>
					<tr>
						<td>
							<a :href="link.link" class="md-list-item-text">{{link.description || link.link}}</a>
						</td>
						<td>
							<md-button class="md-icon-button md-accent" @click="deleteLink(id)">
								<md-icon>delete</md-icon>
							</md-button>
						</td>
					</tr>
				</table>
			</li>
		</ul>
	</div>
</template>

<script>
import AddLink from './AddLink.vue'
export default {
	name: 'DialogLinkTable',
	components: { 'add-link': AddLink },
	props: ['links'],
	data: () => ({
		showAddLink: false
	}),
	methods: {
		deleteLink(id) {
			this.links.splice(id, 1)
		},
		addLink(link) {
			this.links.push(link)
		}
	}
}
</script>

<style scoped>
.link {
	font-size: 15px;
	margin-left: 20px;
}
</style>
